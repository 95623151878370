import React from 'react';
import Terms from '@/components/organisms/Terms';
import TermsHeader from '@/components/atoms/Header/TermsHeader';
import TermsFooter from '@/components/atoms/Footer/TermsFooter';
import { Wrapper } from '@/components/layout/common';
import Seo from '@/components/seo';

const TermsPage = () => {
  return (
    <Wrapper>
      <Seo title="利用規約" />
      <TermsHeader />
      <Terms />
      <TermsFooter />
    </Wrapper>
  );
};

export default TermsPage;
