import React from 'react';
import styled from 'styled-components';
import { Breakpoints } from '@/constants/constants';
import { Main } from '@/components/layout/common';
import theme from '@/styles/theme';

const StyledMain = styled(Main)`
  font: ${theme.fonts.normal_16_24};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 61px 0 0 0;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 71px 0 0 0;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;

const MainArea = styled.div`
  width: 100%;
  max-width: 1062px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
`;
const Title = styled.h1`
  width: 100%;
  text-align: center;
  font: ${theme.fonts.bold_30_45};
  color: #4c586f;
`;

const Article = styled.article`
  width: 100%;
  margin-top: 30px;
`;

const Crosshead = styled.h2`
  width: 100%;
  text-align: left;
  font-weight: bold;
  font: ${theme.fonts.bold_16_24};
  color: #4c586f;
`;

const BracketsOl = styled.ol`
  counter-reset: ol-counter;
`;

const BracketsLi = styled.li`
  line-height: 2em;
  list-style-type: none;
  counter-increment: cnt;
  padding-left: 2em;
  counter-increment: ol-counter;

  &::before {
    content: '(' counter(ol-counter) ')';
    display: inline-block;
    margin-left: -2em;
    width: 2em;
  }
`;

const Ol = styled.ol`
  counter-reset: ol-counter;
`;

const Li = styled.li`
  line-height: 2em;
  list-style-type: none;
  counter-increment: ol-counter;
  padding-left: 1.5em;
  &::before {
    content: counter(ol-counter) '.';
    display: inline-block;
    margin-left: -1.5em;
    width: 1.5em;
  }
`;

const Lead = styled.p`
  line-height: 2em;
`;
const Date = styled.div`
  text-align: right;
  width: 100%;
`;

const Privacy = () => {
  return (
    <StyledMain>
      <TitleWrapper>
        <Title>利用規約</Title>
        <Lead>※ご利用前に必ずお読みください</Lead>
      </TitleWrapper>
      <Wrapper>
        <MainArea>
          <Lead>
            本利用規約（以下「本規約」といいます。）には、株式会社ワークスベイ（以下「当社」といいます。）が提供する不動産取引に関する情報提供サービスであるKUSABI（以下「本サービス」といい、理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスも本サービスに含みます。）のご利用にあたり、不動産のご購入を検討されているユーザーと、不動産のご売却を検討されているユーザーの皆様（総称し、「ユーザー」といいます。）に遵守していただかなければならない事項及び当社とユーザーの皆様との間の権利義務関係が定められております。本サービスをご利用になる方には、本規約が適用されますので、ご利用の前に、必ずお読み下さい。
          </Lead>
          <Article>
            <Crosshead>第１条 （定義）</Crosshead>
            <Lead>本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。</Lead>
            <BracketsOl>
              <BracketsLi>
                「当社ウェブサイト等」とは、そのドメインが「kusabi-cloud.com」である当社が運営するウェブサイト（https://kusabi-cloud.com/）及びアプリ（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
              </BracketsLi>
              <BracketsLi>
                「ルール等」とは、本サービスに関し、当社が別途定めるルール、契約及び覚書諸等のことを指します。
              </BracketsLi>
              <BracketsLi>
                「登録希望ユーザー」とは、当社との間で本契約（本条6号で定義します。）を締結し、本サービスを利用することを希望する個人を指します。
              </BracketsLi>
              <BracketsLi>「登録情報」とは、ユーザーが本サービス上で登録、追加又は変更した情報を指します。</BracketsLi>
              <BracketsLi>
                「取得情報」とは、ユーザーが本サービス利用により取得する不動産取引に関連する情報のことを指します（借入可能金額、購入可能物件及びAIによる不動産価格の査定結果を含みますが、これに限られません。）。
              </BracketsLi>
              <BracketsLi>
                「本契約」とは、本規約にユーザーが同意することで成立する、当社が本サービスの利用をユーザーに許諾する契約を指します。
              </BracketsLi>
            </BracketsOl>
          </Article>
          <Article>
            <Crosshead>第２条 （適用）</Crosshead>
            <Ol>
              <Li>
                本規約は、本サービスに関する当社とユーザーとの間の権利義務関係を定めることを目的として、ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
              </Li>
              <Li>
                ルール等がある場合は、これも本規約に含まれるとみなします。ただし、当該ルール等の内容が本規約に定めのある事項に関するものである場合は、当該ルール等が本規約に優先して適用されます
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第３条 （登録）</Crosshead>
            <Ol>
              <Li>
                登録希望ユーザーは、本規約を遵守することに同意し、かつ当社が別途定める一定の情報（以下「登録事項」といいます。）を当社が別途定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
              </Li>
              <Li>
                当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望ユーザーの登録の可否を判断し、当社が登録を認める場合にはその旨を、当該登録希望ユーザーに通知し、又は本サービスの提供を開始します。登録希望ユーザーのユーザーとしての登録は、当社が本項の通知を行った時点又本サービスの提供を開始した時点のいずれか早いときをもって完了したものとします。
              </Li>
              <Li>
                前項に定める登録の完了時に、本契約がユーザーと当社の間に成立し、ユーザーは本サービスを本規約に従い利用することができるようになります。
              </Li>
              <Li>
                当社は、登録申請ユーザーが、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがありますがその理由については、一切の開示義務を負いません。
                <BracketsOl>
                  <BracketsLi>当社に提供した登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合</BracketsLi>
                  <BracketsLi>登録希望ユーザーが実在しないか、又は実体的な活動を行っていない場合</BracketsLi>
                  <BracketsLi>
                    登録希望ユーザーが過去に当社との契約に違反した事実がある、又はその関係者であると当社が判断した場合
                  </BracketsLi>
                  <BracketsLi>登録希望ユーザーが第11条に定める措置を受けたことがある場合</BracketsLi>
                  <BracketsLi>その他、当社が利用登録を相当でないと判断した場合</BracketsLi>
                </BracketsOl>
              </Li>
              <Li>登録情報は、本サービスの提供のために、本サービスに関連する範囲内で当社が利用できるものとします。</Li>
              <Li>
                当社ウェブサイト等及び本サービスを通じて当社に対して提供されたユーザーの登録情報については、当社が企画運営する関連サイトにも共有され、当社ウェブサイト及び関連サイト等から、ユーザーに対して、電子メールによりメールマガジンや広告等の配信、郵送又は電話によりフェアやセミナーのご案内等を行うことができるものとし、ユーザーはこれに同意しているものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第４条 （登録事項の変更）</Crosshead>
            <Lead>
              ユーザーは、登録事項に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出することで、常に登録事項が真実、正確、完全かつ最新の情報となるよう維持しなければなりません。
            </Lead>
          </Article>
          <Article>
            <Crosshead>第５条 （ユーザーの責任）</Crosshead>
            <Ol>
              <Li>
                ユーザーは、自らの責任と費用において、ハードウェア、ソフトウェア、インターネット接続回線、セキュリティの確保その他本サービスの利用に必要な環境を整備します。
              </Li>
              <Li>本サービスの利用に伴い発生する通信料は、ユーザーの負担とします。</Li>
              <Li>
                ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第６条 （料金及び支払方法）</Crosshead>
            <Lead>本サービスは、別途当社より有料である旨の表記があるサービスを除き、無料とします。</Lead>
          </Article>
          <Article>
            <Crosshead>第７条 （パスワード及びIDの管理）</Crosshead>
            <Ol>
              <Li>
                ユーザーは、自己の責任において、本サービスに関するパスワード及びIDを管理及び保管するものとし、これを第三者に利用させ、貸与、譲渡、名義変更、売買等をしてはならないものとします。
              </Li>
              <Li>
                パスワード又はIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任はユーザーが負うものとし、当社は一切の責任を負いません。
              </Li>
              <Li>
                ユーザーは、パスワード又はIDが盗まれ、又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第８条 （禁止事項）</Crosshead>
            <Ol>
              <Li>
                ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
                <BracketsOl>
                  <BracketsLi>本規約のいずれかの条項に違反する行為</BracketsLi>
                  <BracketsLi>
                    当社、又は本サービスの他の利用者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
                  </BracketsLi>
                  <BracketsLi>
                    犯罪行為に関連する行為、公序良俗に反する行為、法令に反する行為、又は当社若しくは本サービスの他の利用者が所属する業界団体の内部規則に違反する行為
                  </BracketsLi>
                  <BracketsLi>
                    当社若しくは本サービスの他の利用者その他の第三者に対する、詐欺又は脅迫行為、名誉又は信用毀損行為、不当な差別又は誹謗中傷行為
                  </BracketsLi>
                  <BracketsLi>
                    コンピューター・ウィルスその他有害なコンピューター・プログラムを含む情報を送信する行為
                  </BracketsLi>
                  <BracketsLi>本サービスに関し利用しうる情報を改ざんする行為</BracketsLi>
                  <BracketsLi>当社又は他社になりすます行為</BracketsLi>
                  <BracketsLi>不正アクセスに該当する行為</BracketsLi>
                  <BracketsLi>
                    当社が別途定める一定のデータ容量以上のデータを、本サービスを通じて送信する行為
                  </BracketsLi>
                  <BracketsLi>当社が許諾しない本サービス上での宣伝、広告、勧誘、又は営業行為</BracketsLi>
                  <BracketsLi>
                    本サービスの全部若しくは一部又は本サービスにより提供される各種情報を本来の用法を超えて商業目的に転用する行為
                  </BracketsLi>
                  <BracketsLi>当社による本サービスの運営を妨害するおそれのある行為</BracketsLi>
                  <BracketsLi>その他、当社が不適切と判断する行為</BracketsLi>
                </BracketsOl>
              </Li>
              <Li>
                当社は、本サービスにおけるユーザーによる情報の送信行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、ユーザーに事前に通知することなく、当該情報の全部又は一部を削除することができるものとします。当社は、本項に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第９条 （本サービスの停止等）</Crosshead>
            <Ol>
              <Li>
                当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
                <BracketsOl>
                  <BracketsLi>
                    本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
                  </BracketsLi>
                  <BracketsLi>コンピューター、通信回線等が事故により停止した場合</BracketsLi>
                  <BracketsLi>火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</BracketsLi>
                  <BracketsLi>その他、当社が停止又は中断を必要と判断した場合</BracketsLi>
                </BracketsOl>
              </Li>
              <Li>当社は、当社の都合により、本サービスの提供を終了することができます。</Li>
              <Li>
                当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負わず、ユーザーが本サービスに対して支払い済みの料金があった場合もこれを返還しないものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第１０条 （権利帰属）</Crosshead>
            <Ol>
              <Li>
                当社ウェブサイト等及び本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾している第三者に帰属しており、本規約に基づく本サービスの利用許諾は、本サービスに関する当社又は当社にライセンスを許諾している第三者の知的財産権の使用許諾を意味するものではありません。
              </Li>
              <Li>
                当社ウェブサイト等及び本サービスに関する一切の著作権（著作権法27条及び28条に規定する権利を含みます。）、特許権、商標権その他の及び知的財産権（登録等を出願する権利を含みます。）は全て当社に帰属しており、ユーザーは、いかなる理由によっても当社の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしないものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第１１条 （登録取消等）</Crosshead>
            <Ol>
              <Li>
                当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該ユーザーについて本サービスの利用を一時的に停止し、又はユーザーとしての登録を取り消すことができます。
                <BracketsOl>
                  <BracketsLi>本規約のいずれかの条項に違反した場合</BracketsLi>
                  <BracketsLi>登録事項に虚偽の事実があることが判明した場合</BracketsLi>
                  <BracketsLi>
                    当社、又は本サービスの他の利用者その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
                  </BracketsLi>
                  <BracketsLi>手段の如何を問わず、本サービスの運営を妨害した場合</BracketsLi>
                  <BracketsLi>当社に対して負う債務の不履行がある場合</BracketsLi>
                  <BracketsLi>
                    支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
                  </BracketsLi>
                  <BracketsLi>差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合</BracketsLi>
                  <BracketsLi>租税公課の滞納処分を受けた場合</BracketsLi>
                  <BracketsLi>営業を廃止したとき、又は清算手続にはいった場合</BracketsLi>
                  <BracketsLi>6ヶ月間以上本サービスの利用がない場合</BracketsLi>
                  <BracketsLi>
                    当社からの問いあわせその他回答を求める連絡に対して当社が定める期間内に応答がない場合
                  </BracketsLi>
                  <BracketsLi>第3条第4項各号に該当する場合</BracketsLi>
                  <BracketsLi>その他、当社がユーザーとしての登録の継続を適当でないと判断した場合</BracketsLi>
                </BracketsOl>
              </Li>
              <Li>
                前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負担する債務又は本サービスを通じて締結した契約等により生じた債務の一切について当然に期限の利益を失い、直ちに全ての債務の弁済を行わなければなりません。
              </Li>
              <Li>
                当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について一切の責任を負わず、ユーザーが本サービスに対して支払い済みの料金があった場合もこれを返還しないものとします。
              </Li>
              <Li>
                本条に基づきユーザーの登録が取り消された場合、ユーザーは、当社の指示に基づき、当社から提供を受けた本サービスに関連するソフトウェア、マニュアルその他の物があるときには、それらにつき、返還、廃棄その他の処分を行うものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第１２条 （解除）</Crosshead>
            <Ol>
              <Li>
                ユーザーは、当社が別途定める方法で当社に通知することにより、本サービスの利用契約を将来に向かって解除し、自己のユーザーとしての登録を抹消することができるものとします。
              </Li>
              <Li>
                前項に基づき本サービスの利用契約が解除された場合であっても、当社は、当該解除までにユーザーから受領した本サービスの利用料金を返還しないものとします。
              </Li>
              <Li>
                ユーザーからの解除にあたり、当社に対して負っている債務が有る場合は、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
              </Li>
              <Li>
                当社は、解除をおこなったユーザーの登録情報及び取得情報については、継続して保有する義務を負わないものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第１３条 （保証の否認及び免責）</Crosshead>
            <Ol>
              <Li>
                当社は、当社が保有する登録情報に関する情報の真実性、最新性、確実性等につき一切保証をせず、登録情報に関する何らの保証も行わないものとします。
              </Li>
              <Li>
                当社は、取得情報の真実性、最新性、確実性等につき一切保証をせず、取得情報に関する何らの保証も行わず、一切の責任を負わないものとします。
              </Li>
              <Li>
                当社は、本サービス又は本サービスを通じて提供される各種サービス及び取得情報がユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること及び不具合が生じないことについて、何ら保証を行わず、一切の責任を負わないものとします。
              </Li>
              <Li>
                当社は、本サービス又は本サービスの利用が、ユーザーに対して適用のある法令又は業界団体内部規則等に適合すること、継続的な利用ができること、欠陥・エラー・バグがないこと、ユーザー又は第三者の権利侵害が生じないこと、及び外部機器に不具合が生じないことにつき、何ら保証を行わず、一切の責任を負わないものとします。
              </Li>
              <Li>
                当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、ユーザーのメッセージ又は情報の削除又は消失､ユーザーの登録の取消、取得情報の削除又は消失、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連してユーザーが被った損害につき、一切の責任を負わないものとします。
              </Li>
              <Li>
                当社は、当社に故意又は重過失が認められない限り、当社ウェブサイト等又は本サービスを通じてユーザーと本サービスの他の利用者その他の第三者との間に生じたいかなる紛争についても一切責任を負いません。当社が直接これにやむを得ず対応した場合、ユーザーは、当社に発生した損害、費用（合理的な弁護士費用を含みます）等の一切を補償するものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第１４条 （秘密保持）</Crosshead>
            <Ol>
              <Li>
                ユーザーは、本サービスに関連して当社がユーザーに対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとし、第三者に開示することはできないものとします。
              </Li>
              <Li>
                当社は、本サービスの利用において、ユーザーが開示した情報については、特段の取り決めを定めた場合を除き、秘密情報としての取り扱いをする義務を負いません。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第１５条 （利用者情報の取扱い）</Crosshead>
            <Ol>
              <Li>
                当社によるユーザーその他の本サービスの利用者情報の取扱いについては、別途に定める当社プライバシーポリシー（
                https://kusabi-cloud.com/）によるものとし、ユーザーはこのプライバシーポリシーに従って当社がユーザーの利用情報等を取扱うことについて同意するものとします。
              </Li>
              <Li>
                当社は、ユーザーが当社に提供した情報、データ等を、ユーザーを特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第１６条 （本サービスの変更）</Crosshead>
            <Ol>
              <Li>当社は、当社の判断により、本サービスの内容を変更又は提供を終了することができます。</Li>
              <Li>
                当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害については一切の責任を負いません。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第１７条 （本規約等の変更）</Crosshead>
            <Ol>
              <Li>当社は、本規約及びルール等を変更できるものとします。</Li>
              <Li>
                当社は、本規約を変更したときは、当社ウェブサイト等にアップロードする方法によりユーザーに通知するものとし、同通知には変更後の規約の効力発生日を明記するものとします。
              </Li>
              <Li>
                本規約の変更がユーザーの一般の利益に適合するとともに本規約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、その他の変更に係る事情に照らして合理的なものであるときは、本規約の変更は、当該変更内容の通知後、ユーザーが本サービスを利用した場合又は当社が別途定める期間内に登録取消の手続をとらなかった場合には、ユーザーは、本規約の変更に同意したものとみなします。
              </Li>
              <Li>
                本規約の変更が前項の要件を満たさない場合は、本規約の変更はユーザーの同意を得たときから効力を生じるものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第１８条 （連絡・通知）</Crosshead>
            <Ol>
              <Li>
                本サービスに関する問合せその他ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社からユーザーに対する連絡又は通知は、ユーザーが当社へ提供したメールアドレスへのメール送信又は当社が別途定めるその他の方法により行うものとします。なお、当社がユーザーから提供された連絡先に連絡をした場合、ユーザーから当社に対する連絡先変更届出がなされない限り、当社は現在登録されている連絡先に対する連絡又は通知を有効とみなします。
              </Li>
              <Li>本サービスに関するユーザーから当社への連絡は、当社が定める方法により行うものとします。</Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第１９条 （権利の譲渡等）</Crosshead>
            <Ol>
              <Li>
                ユーザーは、当社又はユーザーの書面による事前の承諾なく、本契約上の地位、本規約に基づく権利義務又は本サービスを通じて成立した契約に基づく権利義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
              </Li>
              <Li>
                当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い本契約上の地位、本規約に基づく権利及び義務並びにユーザーの登録情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第２０条 （損害賠償）</Crosshead>
            <Ol>
              <Li>
                ユーザーは、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
              </Li>
              <Li>
                当社は、故意又は重過失により本規約等に違反し、ユーザーに損害が生じた場合、本規約により免責される場合除き、相手方に対し、直接かつ現実に生じた損害を賠償する義務を負います。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第２１条 （反社会勢力の排除）</Crosshead>
            <Ol>
              <Li>
                当社及びユーザーは、自己又は自己の代理人もしくは媒介をする者が、現在、暴力団、暴力団員、暴力団員でなくなったときから５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下これらを総称して「反社会的勢力」といいます。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明、保証し、かつ将来にわたっても該当しないことを相互に確約します。
                <BracketsOl>
                  <BracketsLi>「反社会的勢力」が経営を支配していると認められる関係を有すること</BracketsLi>
                  <BracketsLi>「反社会的勢力」が経営に実質的に関与していると認められる関係を有すること</BracketsLi>
                  <BracketsLi>
                    自己もしくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に「反社会的勢力」を利用していると認められる関係を有すること
                  </BracketsLi>
                  <BracketsLi>
                    「反社会的勢力」に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
                  </BracketsLi>
                  <BracketsLi>
                    役員又は経営に実質的に関与している者が「反社会的勢力」と社会的に非難されるべき関係を有すること
                  </BracketsLi>
                </BracketsOl>
              </Li>
              <Li>
                当社及びユーザーは、前項の確約に反して、相手方当事者又は相手方当事者の代理もしくは媒介をする者が「反社会的勢力」あるいは前項各号の一にでも該当することが判明したときは、何らの催告を要せず直ちに本契約を解除することができるものとします。
              </Li>
              <Li>
                当社及びユーザーが前項の規定により契約を解除した場合には、解除により相手方に生じた損害の一切について賠償する義務を負わないものとします。
              </Li>
            </Ol>
          </Article>
          <Article>
            <Crosshead>第２２条 （完全合意）</Crosshead>
            <Lead>
              本規約は、本規約に含まれる事項に関する当社とユーザーとの完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社とユーザーの事前の合意、表明、及び了解に優先します。
            </Lead>
          </Article>
          <Article>
            <Crosshead>第２３条 （分離可能性）</Crosshead>
            <Lead>
              本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
            </Lead>
          </Article>
          <Article>
            <Crosshead>第２４条 （準拠法及び管轄裁判所）</Crosshead>
            <Lead>
              本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、訴額に応じ、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </Lead>
          </Article>
          <Article>
            <Crosshead>第２５条 （協議解決）</Crosshead>
            <Lead>
              当社及びユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
            </Lead>
          </Article>
          <Date>
            <Lead>2022/07/04 現在</Lead>
          </Date>
        </MainArea>
      </Wrapper>
    </StyledMain>
  );
};

export default Privacy;
