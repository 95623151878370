import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { slide as Menu } from 'react-burger-menu';
import { Routings } from '@/common/routings';
import { Breakpoints } from '@/constants/constants';
import HeaderLogo from '../Components/HeaderLogo';
import {
  Header,
  ListItem,
  MenuInner,
  Navigation,
  NavWrapper,
  SpList,
  SpListItem,
  SpListItemLink,
  SpSection,
  StyledLink,
  styles,
} from '../Components/StyledComponent';
import TermsSection from '../Components/TermsSection';
import HamburgerMenu from '../../HamburgerMenu';
import SlashIcon from '../../Icons/SlashIcon';

const TermsHeader = () => {
  const [isOpenSpMenu, setOpenSpMenu] = useState(false);

  useEffect(() => {
    return () => {
      const bodyEl = document.querySelector('body');
      if (!bodyEl) return;
      bodyEl.style.overflow = '';
    };
  }, []);

  const handleOnClickSpMenu = () => {
    const isnextOpen = !isOpenSpMenu;
    setOpenSpMenu(isnextOpen);
    const bodyEl = document.querySelector('body');
    if (!bodyEl) return;
    if (isnextOpen) {
      // SPメニュー表示時は後ろのスクロールを禁止する
      bodyEl.style.overflow = 'hidden';
    } else {
      bodyEl.style.overflow = '';
    }
  };

  return (
    <>
      <Header>
        <HeaderLogo serviceType="buyer" />
        <NavWrapper>
          {/* PCヘッダー */}
          <MediaQuery minWidth={`${Breakpoints.sp + 1}px`}>
            <Navigation>
              <ListItem>
                <StyledLink to={Routings.buyerInquiry.location}>お問合せ</StyledLink>
              </ListItem>
            </Navigation>
          </MediaQuery>
          {/* SPヘッダー */}
          <MediaQuery maxWidth={`${Breakpoints.sp}px`}>
            <Navigation>
              <HamburgerMenu isOpen={isOpenSpMenu} onClick={handleOnClickSpMenu} />
            </Navigation>
          </MediaQuery>
        </NavWrapper>
      </Header>

      {/* SPメニュー */}
      <MediaQuery maxWidth={`${Breakpoints.sp}px`}>
        <Menu right isOpen={isOpenSpMenu} customBurgerIcon={false} styles={styles}>
          <MenuInner>
            <SpSection>
              <SpList>
                <SpListItem isTop>
                  <SpListItemLink to={Routings.buyerTop.location}>
                    <SlashIcon size="small" color="blue" />
                    トップ
                  </SpListItemLink>
                </SpListItem>
                <SpListItem>
                  <SpListItemLink to={Routings.buyerInquiry.location}>
                    <SlashIcon size="small" color="blue" />
                    お問合せ
                  </SpListItemLink>
                </SpListItem>
              </SpList>
            </SpSection>
            <TermsSection />
          </MenuInner>
        </Menu>
      </MediaQuery>
    </>
  );
};

export default TermsHeader;
