import React, { memo } from 'react';
import { Breakpoints } from '@/constants/constants';
import TermsLink from '../Components/TermsLink';
import Copyright from '../Components/Copyright';
import MediaQuery from 'react-responsive';
import FooterLogo from '../Components/FooterLogo';
import ScrollTop from '../../ScrollTop';
import { StyledFooter, Warpper } from '../Components/StyledComponent';

const TermsFooter = () => {
  return (
    <Warpper>
      <MediaQuery maxWidth={`${Breakpoints.sp}px`}>
        <ScrollTop fullWidth />
      </MediaQuery>

      {/* 共通 */}
      <StyledFooter id="footer">
        <TermsLink />
        <Copyright />
        <FooterLogo />
      </StyledFooter>
    </Warpper>
  );
};

export default memo(TermsFooter);
